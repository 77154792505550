import React from "react";
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/leasing';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Simplify from './Simplify';
import ExtendPortfolio from './ExtendPortofolio';
import Motivation from './Motivation';
import CallToAction from '../../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.industries._}
    subpage={sitemap.leasing._}
    subheader={Subheader}
    url='_url:leasing'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Simplify/>
    <ExtendPortfolio/>
    <Motivation/>
    <CallToAction/>
  </Layout>
);