import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import TextLoop from 'react-text-loop';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import Anchor from '../../../../components/Anchor';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        hero: imageSharp(fluid: {
          originalName: {eq: "Leasing-Hero.jpg"}
        }) {
          fluid(maxWidth: 1900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero }) => (
      <Grid container justify='center'>
        <Anchor id='main'/>
        <Grid container 
          alignItems='center'
          direction='row-reverse'
          className={styles.container}>
          <Grid item sm={12} md={12}
            className={styles.imageContainer}>
            <Img imgStyle={{ objectFit: 'contain'}}
              objectFit='cover'
              className={styles.image}
              fluid={hero.fluid}
              alt="Flådestyringsløsning obiplus"/>
          </Grid>
          <Grid item container xs={12} sm={12} md={6} className={styles.textContainer}>
            <Grid item container className={styles.productNameContainer}>
              <Typography
                variant='h1'
                weight='light'
                className={clsx(
                  styles.heroText,
                  styles.title,
                  styles.productName
                )}>
                {i18n('Connected car services for leasing')}
              </Typography>
            </Grid>
            <Typography
              variant='h2'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitch,
                styles.heroText
              )}>
              {i18n('A smart solution to')}
            </Typography>
            <Typography
              color='secondary'
              variant='h2'
              weight='regular'
              align='left'
              className={clsx(
                styles.pitchLoop,
                styles.pitchLoopText,
                styles.heroText
              )}>
              <TextLoop springConfig={{
                  stiffness: 1000,
                  damping: 40
                }}>
                {[
                  i18n('manage vehicles'),
                  i18n('ease administration'),
                  i18n('add value')
                ]}
              </TextLoop>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )}/>
);